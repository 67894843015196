import backend from "@/api/rest";
import router from "@/router";
import store from "@/store";

export async function getCode(store, { login, captcha, captcha_secret }) {
  await backend.post("/code", { login, captcha, captcha_secret });
}

export async function getCaptcha() {
  let result = {};

  await backend
    .get("/captcha", { responseType: "arraybuffer" })
    .then((response) => {
      result = {
        imgBase64: Buffer.from(response.data, "binary").toString("base64"),
        secret: response.headers["secret-code"],
      };
    });

  return result;
}

export async function getUserInfo({ commit }) {
  await backend
    .get("/client/info")
    .then((result) => {
      commit("setUser", result.data.data);
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response?.status === 401) {
        store.commit("user/killUser");
      }
    });
}

export async function userAuth({ commit, dispatch }, { token }) {
  commit("setBackendToken", token);
  await dispatch("getUserInfo").then(() => {
    commit("setIsAuthenticated", true);
    router.push({ name: "LkAbout" });
  });
}
