<template>
  <div class="auth-popup__content">
    <Icon :type="'crest'" @click="currentPopup.isShown = false" />
    <div class="auth-popup__start" v-show="start">
      <div class="auth-popup__title">
        <span>
          Вход в личный кабинет <br />
          «ЛИГА ФОРУМ»
        </span>
      </div>
      <Button red @click="authClick"> Войти </Button>
      <Button blue @click="loginClick"> Зарегистрироваться </Button>
    </div>
    <div class="auth-popup__main" v-show="!start">
      <div class="auth-popup__title">
        <span>{{ authMode === false ? "Регистрация" : "Вход" }}</span>
        <p v-if="step !== 'code'">
          На вашу электронную почту отправлен уникальный PIN-КОД
        </p>
      </div>
      <div class="auth-popup__form" v-if="step === 'code'">
        <Input
          label="Email"
          v-model="formData.emailValue"
          type="email"
          :error-array="v$.formData.emailValue.$errors"
          :error-text="checkEmailError"
          @blur="checkEmails"
          :error="
            v$.formData.emailValue.$errors.length !== 0 || !!checkEmailError
          "
        />
        <div class="auth-popup__captcha">
          <div
            class="auth-popup__captcha-img"
            :class="{ disabled: !reloadCaptchaAgree }"
          >
            <img
              :src="`data:image/gif;base64,${captchaData.imgBase64}`"
              class="captcha-image"
              alt="captcha"
            />
            <Icon type="spinner" @click="reloadCaptcha" />
          </div>
        </div>
        <Input
          label="Код с картинки"
          v-model="formData.captchaValue"
          type="text"
          mask="####"
          :error-array="v$.formData.captchaValue.$errors"
          @blur="v$.formData.captchaValue.$touch"
          :error="v$.formData.captchaValue.$errors.length !== 0"
        />
      </div>
      <div
        class="auth-popup__form"
        v-if="step === 'register' && authMode === false"
      >
        <Input
          label="Email"
          v-model="formData.emailValue"
          type="email"
          :error-array="v$.formData.emailValue.$errors"
          @blur="v$.formData.emailValue.$touch"
          :error="v$.formData.emailValue.$errors.length !== 0"
          disabled
        />
        <Input
          label="Код из email"
          v-model="formData.codeValue"
          type="text"
          mask="######"
          :error-array="v$.formData.codeValue.$errors"
          @blur="v$.formData.codeValue.$touch"
          :error="v$.formData.codeValue.$errors.length !== 0"
        />
        <div class="auth-popup__spam">
          <p>
            При необходимости на электронной почте проверьте папку
            «<span>Спам</span>»
          </p>
          <Button
            red
            @click="handleSubmit"
            :loading="isDisabled"
            :disabled="formIncorrect"
            ref="regButton"
          >
            Зарегистрироваться
          </Button>
        </div>
      </div>
      <div
        class="auth-popup__form"
        v-if="step === 'logIn' && authMode === true"
      >
        <Input
          label="Email"
          v-model="formData.emailValue"
          type="email"
          :error-array="v$.formData.emailValue.$errors"
          @blur="v$.formData.emailValue.$touch"
          :error="v$.formData.emailValue.$errors.length !== 0"
          disabled
        />
        <Input
          label="Код из email"
          v-model="formData.codeValue"
          type="text"
          mask="######"
          :error-array="v$.formData.codeValue.$errors"
          @blur="v$.formData.codeValue.$touch"
          :error="v$.formData.codeValue.$errors.length !== 0"
        />
        <div class="auth-popup__spam">
          <p>
            При необходимости на электронной почте проверьте папку
            «<span>Спам</span>»
          </p>
          <Button
            red
            @click="handleSubmit"
            :loading="isDisabled"
            :disabled="formIncorrect"
            ref="loginButton"
          >
            Войти
          </Button>
        </div>
      </div>
      <div v-if="filteredErrors.length !== 0" class="auth-popup__errors">
        <span v-for="(item, index) in filteredErrors" :key="index">
          {{ item.error_descr }}
        </span>
      </div>
      <div class="auth-popup__btns" v-if="step === 'code'">
        <Button
          red
          @click="enterHandleSubmit"
          :loading="isDisabled"
          :disabled="formIncorrect"
          :ref="'submitButton'"
          @keydown.enter="enterHandleSubmit()"
        >
          Отправить код
        </Button>
      </div>
      <router-link
        :to="{ name: 'Policy' }"
        class="auth-popup__policy"
        target="_blank"
      >
        Нажимая на кнопку, вы соглашаетесь с нашей Политикой конфиденциальности
      </router-link>
    </div>
  </div>
</template>

<script>
import Input from "@/components/Blocks/Input";
import { createNamespacedHelpers, mapState } from "vuex";
import Button from "@/components/Blocks/Button";
const { mapActions } = createNamespacedHelpers("user");
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
import useVuelidate from "@vuelidate/core";
import { checkTrueEmails } from "@/utils/helpers";
import {
  helpers,
  required,
  email,
  minLength,
  maxLength,
} from "@vuelidate/validators";
import Icon from "@/components/Blocks/Icon";

export default {
  name: "AuthPopup",
  components: {
    Icon,
    Button,
    Input,
  },
  setup() {
    return {
      v$: useVuelidate({ $lazy: true }),
    };
  },
  data() {
    return {
      formIncorrect: false,
      authMode: false,
      step: "code",
      start: true,
      isDisabled: false,
      errors: [],
      checkEmailError: "",
      filteredErrors: [],
      formData: {
        emailValue: "",
        captchaValue: "",
        codeValue: "",
      },
      captchaData: {
        imgBase64: "",
        secret: "",
      },
      reloadCaptchaAgree: true,
    };
  },
  validations() {
    return {
      formData: {
        emailValue: {
          required: helpers.withMessage("Обязательно к заполнению", required),
          email: helpers.withMessage("Неправильный формат", email),
        },
        captchaValue: {
          required: helpers.withMessage("Обязательно к заполнению", required),
        },
        codeValue: {
          required:
            this.step !== "code"
              ? helpers.withMessage("Обязательно к заполнению", required)
              : "",
          minLength: helpers.withMessage(
            "Код из email должен состоять из 6 символов",
            minLength(6)
          ),
          maxLength: helpers.withMessage(
            "Код из email должен состоять из 6 символов",
            maxLength(6)
          ),
        },
      },
    };
  },
  watch: {
    "v$.$errors.length"() {
      this.v$.$errors.length === 0 ? (this.formIncorrect = false) : true;
    },
  },
  computed: {
    ...mapState(["currentPopup"]),
  },
  methods: {
    authClick() {
      this.authMode = true;
      this.start = false;
      this.currentPopup.isShown = true;
    },
    loginClick() {
      this.authMode = false;
      this.start = false;
      this.currentPopup.isShown = true;
    },
    escCloseModal(e) {
      if (this.currentPopup.isShown && e.key === "Escape") {
        this.currentPopup.isShown = false;
      }
    },
    enterModal(e) {
      if (this.step === "code" && e.key === "Enter") {
        this.enterHandleSubmit();
      } else if (e.key === "Enter") {
        this.handleSubmit();
      }
    },
    findError(error, array) {
      return array.some((item) => {
        return item.error_name === error;
      });
    },
    async enterHandleSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return (this.formIncorrect = true);
      this.isDisabled = true;
      if (this.step === "code") {
        await createRequest(requestConfigs.POSTCodeRequest, {
          jsonPayload: {
            login: this.formData.emailValue,
            captcha: this.formData.captchaValue,
            captcha_secret: this.captchaData.secret,
          },
        })
          .then(() => {
            this.filteredErrors = 0;
            this.authMode === false
              ? (this.step = "register")
              : (this.step = "logIn");
          })
          .finally(() => {
            this.isDisabled = false;
          })
          .catch((error) => {
            this.filteredErrors = error.errors;
            if (this.filteredErrors.length !== 0) this.step = "code";
            if (this.findError("liga_captcha", error.errors)) {
              this.filteredErrors = this.errors.filter((item) => {
                return item.error_name !== "liga_captcha";
              });
              this.filteredErrors.push({
                error_descr: "Неверно введён код с картинки",
              });
            }
          });
      }
    },
    async handleSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return (this.formIncorrect = true);
      this.isDisabled = true;
      if (this.step === "register") {
        await createRequest(requestConfigs.POSTSignUp, {
          jsonPayload: {
            email: this.formData.emailValue,
            code: this.formData.codeValue,
          },
        })
          .then((result) => {
            this.$store.dispatch("user/userAuth", {
              token: result.data.token,
            });
            this.$store.state.currentPopup.isShown = false;
          })
          .finally(() => {
            this.isDisabled = false;
          })
          .catch((error) => {
            this.errors = error.errors;
            if (
              this.findError("check_code", error.errors) &&
              this.findError("is_exist_email", error.errors)
            ) {
              this.filteredErrors = this.errors
                .filter((item) => {
                  return item.error_name !== "check_code";
                })
                .filter((item) => {
                  return item.error_name !== "is_exist_email";
                });
              this.filteredErrors.push(
                {
                  error_descr: "Неверный код из email",
                },
                { error_descr: "Указанный email уже существует" }
              );
              this.$store.state.currentPopup.isShown = true;
            } else if (this.findError("check_code", error.errors)) {
              this.filteredErrors = this.errors.filter((item) => {
                return item.error_name !== "check_code";
              });
              this.filteredErrors.push({
                error_descr: "Неверный код из email",
              });
              this.$store.state.currentPopup.isShown = true;
            } else if (this.findError("is_exist_email", error.errors)) {
              this.filteredErrors = this.errors.filter((item) => {
                return item.error_name !== "is_exist_email";
              });
              this.filteredErrors.push({
                error_descr: "Указанный email уже существует",
              });
              this.$store.state.currentPopup.isShown = true;
            }
          });
      } else if (this.step === "logIn") {
        await createRequest(requestConfigs.POSTSignIn, {
          jsonPayload: {
            email: this.formData.emailValue,
            code: this.formData.codeValue,
          },
        })
          .then((result) => {
            this.$store.dispatch("user/userAuth", {
              token: result.data.token,
            });
            this.$store.state.currentPopup.isShown = false;
          })
          .finally(() => {
            this.isDisabled = false;
          })
          .catch((error) => {
            this.errors = error.errors;
            if (
              this.findError("check_code", error.errors) &&
              this.findError("email", error.errors)
            ) {
              this.filteredErrors = this.errors
                .filter((item) => {
                  return item.error_name !== "check_code";
                })
                .filter((item) => {
                  return item.error_name !== "email";
                });
              this.filteredErrors.push(
                {
                  error_descr: "Неверный код из email",
                },
                { error_descr: "Указанный email не существует" }
              );
              this.$store.state.currentPopup.isShown = true;
            } else if (this.findError("check_code", error.errors)) {
              this.filteredErrors = this.errors.filter((item) => {
                return item.error_name !== "check_code";
              });
              this.filteredErrors.push({
                error_descr: "Неверный код из email",
              });
              this.$store.state.currentPopup.isShown = true;
            } else if (this.findError("email", error.errors)) {
              this.filteredErrors = this.errors.filter((item) => {
                return item.error_name !== "email";
              });
              this.filteredErrors.push({
                error_descr: "Указанный email не существует",
              });
              this.$store.state.currentPopup.isShown = true;
            }
          });
      }
    },
    checkEmails() {
      this.v$.formData.emailValue.$touch;
      if (this.checkTrueEmails(this.formData.emailValue)) {
        this.checkEmailError =
          "Недопустимый адрес почты. Введите другую почту.";
        this.formIncorrect = true;
      } else {
        this.checkEmailError = "";
        this.formIncorrect = false;
      }
    },
    loadCaptcha() {
      this.getCaptcha().then((result) => {
        this.captchaData = result;
      });
    },
    reloadCaptcha() {
      this.loadCaptcha();
      this.reloadCaptchaAgree = false;
      setTimeout(() => {
        this.reloadCaptchaAgree = true;
      }, 60000);
    },
    ...mapActions(["getCaptcha", "getCode"]),
    checkTrueEmails,
  },
  mounted() {
    this.loadCaptcha();
    window.addEventListener("keydown", this.escCloseModal);
    window.addEventListener("keydown", this.enterModal);
  },
  destroy() {
    window.removeEventListener("keydown", this.escCloseModal);
    window.removeEventListener("keydown", this.enterModal);
  },
};
</script>

<style lang="scss">
.auth-popup {
  .modal-box {
    max-width: 700px;
    width: 100%;
    padding: 0;
    overflow: unset;
    min-height: unset !important;
  }
  &__start {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .auth-popup__title {
      @include text30();
    }
  }
  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__content {
    background-color: #ffffff;
    border-radius: 15px;
    height: 100%;
    padding: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow-y: auto;
    @include scrollbar();
    i {
      position: absolute;
      right: 15px;
      top: 15px;
      font-size: 20px;
      cursor: pointer;
      &:hover {
        -webkit-text-stroke-width: 0.5px;
      }
    }
  }
  &__title {
    font-size: 50px;
    line-height: 75px;
    font-weight: 700;
    color: #292929;
    margin-bottom: 35px;
    text-align: center;
    max-width: 400px;
    p {
      font-size: 18px;
      line-height: 22px;
    }
  }
  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    button {
      margin: 0 auto;
    }
  }
  &__captcha {
    display: flex;
    justify-content: center;
    &-img {
      position: relative;
      img {
        height: 80px;
        object-fit: contain;
        border-radius: 15px;
        overflow: hidden;
        margin: 0 auto;
      }
      i {
        font-size: 15px;
        right: -5px;
        top: -5px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        background: peachpuff;
        padding: 10px;
        border-radius: 50%;
      }
      &.disabled {
        i {
          pointer-events: none;
          filter: grayscale(1);
        }
      }
    }
  }
  &__btns {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
  &__errors {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 5px;
    padding: 0 20px;
    span {
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
      color: var(--red);
    }
  }
  &__policy {
    text-align: center;
    margin-top: 20px;
    color: var(--btn-gray-color1);
    text-decoration: underline;
    &:hover {
      -webkit-text-stroke-width: 0.5px;
    }
  }
  &__spam {
    p {
      font-size: 16px;
      line-height: 19px;
      font-weight: 600;
      color: var(--black2);
      margin-bottom: 20px;
      text-align: center;
      span {
        color: var(--red);
        font-weight: 700;
      }
    }
  }
  @include adaptive(tablet-big) {
    .modal-box {
      max-height: unset !important;
    }
  }
  @include adaptive(phone) {
    .modal-box {
    }
    &__content {
      padding: 20px;
    }
    &__title {
      font-size: 20px;
      line-height: 27px;
    }
    &__btns {
      flex-direction: column;
      align-items: center;
      width: 100%;
      .btn {
        width: 100%;
      }
    }
  }
}
</style>
