export default {
  POSTCodeRequest: {
    url: "/code",
    type: "post",
    jsonKeys: ["login", "captcha", "captcha_secret"],
  },
  POSTSignUp: {
    url: "/signup",
    type: "post",
    jsonKeys: ["email", "code"],
  },
  POSTSignIn: {
    url: "/signin",
    type: "post",
    jsonKeys: ["email", "code"],
  },
  GETInfoBlock: {
    url: "/info-blocks/one",
    type: "get",
    queryKeys: ["key_block", "limit", "key"],
  },
  GETShiftList: {
    url: "/shifts/all",
    type: "get",
  },
  GETShift: {
    url: "/shifts/:{id}",
    type: "get",
    routerKeys: ["id"],
  },
  GETAllParams: {
    url: "/client/info/statement-param",
    type: "get",
  },
  POSTSaveStudentInfo: {
    url: "/client/info/save",
    type: "post",
    jsonKeys: [
      "id",
      "fio",
      "email",
      "fa2",
      "phone",
      "gender",
      "telegram",
      "telegram_name",
      "first_name",
      "last_name",
      "middle_name",
      "birthday",
      "birthday_format",
      "is_terms_confirm",
      "locale",
      "group",
      "faculty",
      "course_level",
      "avatar",
      "avatar_crop",
      "created_at",
      "city_id",
      "region_id",
      "educational_establishment_id",
      "socials",
    ],
  },
  GETListTasks: {
    url: "/client/tasks/list-tasks",
    type: "get",
  },
  POSTRequest: {
    url: "/client/statement/store",
    type: "post",
    jsonKeys: [
      "shift_id",
      "first_direction_id",
      "second_direction_id",
      "third_direction_id",
      "course_level",
      "clothing_size",
      "community_represent_id",
      "community_represent_other",
      "community_represent_name",
      "position_organization_other",
      "position_organization_id",
      "what_does_student_mean_you",
      "purpose_travel",
      "your_main_achievements",
      "degree_from_league_id",
      "hear_about_forum_id",
      "health_features",
      "food_preference_id",
      "hear_about_forum_other",
      "hear_about_forum_friend",
      "first_team_id",
      "second_team_id",
      "third_team_id",
      "is_valid_shift",
    ],
  },
  POSTRequestUpdate: {
    url: "/client/statement/:{id}/update",
    type: "post",
    routerKeys: ["id"],
    jsonKeys: [
      "first_direction_id",
      "second_direction_id",
      "third_direction_id",
      "course_level",
      "clothing_size",
      "community_represent_id",
      "community_represent_other",
      "community_represent_name",
      "position_organization_id",
      "position_organization_other",
      "what_does_student_mean_you",
      "purpose_travel",
      "your_main_achievements",
      "degree_from_league_id",
      "health_features",
      "food_preference_id",
      "hear_about_forum_id",
      "hear_about_forum_other",
      "hear_about_forum_friend",
      "first_team_id",
      "second_team_id",
      "third_team_id",
      "shift_id",
    ],
  },
  POSTTasksSave: {
    url: "/client/tasks/:{id}/save-task-answer",
    type: "post",
    routerKeys: ["id"],
    jsonKeys: ["task_id", "value"],
  },
  GETInfo: {
    url: "/client/info",
    type: "get",
  },
  GETUnlinkTg: {
    url: "/client/info/telegram-unlink",
    type: "get",
  },
  POSTRollbackStatement: {
    url: "/client/statement/:{id}/refusal-self",
    type: "post",
    routerKeys: ["id"],
    jsonKeys: ["comment_refusal"],
  },
};
