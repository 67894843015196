import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import store from "@/store";
import moment from "moment";
// import PrimeVue from "primevue/config";
// import usePrimeVueComponents from "@/plugins/primeVue";

// import "primevue/resources/themes/soho-dark/theme.css";

const Vue = createApp(App);
// usePrimeVueComponents(Vue);

Vue.use(router).use(store).use(moment);
// .use(PrimeVue, { ripple: true });

Vue.mount("#app");
