<template>
  <div class="map-popup__main">
    <Icon :type="'crest'" @click="currentPopup.isShown = false" class="close" />
    <div class="map-popup__head">
      <Tabs
        :items="[1, 2]"
        :keys="['Субъекты', 'Учебные заведения']"
        v-model="main"
      />
    </div>
    <div class="map-popup__body">
      <div class="map-popup__body--subjects" v-if="main === 1">
        <div class="table">
          <div class="table__head">
            <div class="table__row">
              <div>Субъект</div>
              <div>Кол-во зарегистрированных</div>
              <div>Кол-во поданных заявок</div>
            </div>
          </div>
          <div class="table__body">
            <div
              class="table__row"
              v-for="(item, index) in subjectsInfo"
              :key="index"
            >
              <div data-label="Субъект">{{ item.region_name }}</div>
              <div data-label="Кол-во зарегистрированных">
                {{ item.count_students }}
              </div>
              <div data-label="Кол-во поданных заявок">
                {{ item.count_statements }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="map-popup__body--university" v-if="main === 2">
        <Tabs :items="[1, 2]" :keys="['ВУЗы', 'СПО']" v-model="university" />
        <div class="table" v-if="universityInfo.length > 0">
          <div class="table__head">
            <div class="table__row">
              <div>Учебное заведение</div>
              <div>Кол-во зарегистрированных</div>
              <div>Кол-во поданных заявок</div>
            </div>
          </div>
          <div class="table__body">
            <div
              class="table__row"
              v-for="(item, index) in universityInfo"
              :key="index"
            >
              <div data-label="Субъект">{{ item.educational_name }}</div>
              <div data-label="Кол-во зарегистрированных">
                {{ item.count_students }}
              </div>
              <div data-label="Кол-во поданных заявок">
                {{ item.count_statements }}
              </div>
            </div>
          </div>
        </div>
        <div v-else class="map-popup__body--empty">Нет данных</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Icon from "@/components/Blocks/Icon";
import Tabs from "@/components/Blocks/Tabs";

export default {
  name: "MapPopup",
  components: { Tabs, Icon },
  data() {
    return {
      main: 1,
      university: 1,
    };
  },
  computed: {
    info() {
      return JSON.parse(JSON.stringify(this.currentPopup.props.info));
    },
    subjectsInfo() {
      return this.info.maps_info.data
        .filter((item) => {
          return item.count_statements > 0 && item.count_students > 0;
        })
        .sort((a, b) => {
          return b.count_students - a.count_students;
        })
        .sort((a, b) => {
          return b.count_statements - a.count_statements;
        });
    },
    universityInfo() {
      return this.info.university_info.data
        .filter((item) => {
          return this.university === 1
            ? item.educational_type === "vuz"
            : item.educational_type === "suz";
        })
        .sort((a, b) => {
          return b.count_students - a.count_students;
        })
        .sort((a, b) => {
          return b.count_statements - a.count_statements;
        });
    },
    ...mapState(["currentPopup"]),
  },
};
</script>

<style lang="scss">
.map-popup {
  &.popup-wrapper .modal-box {
    max-width: 1000px;
    width: 100%;
    max-height: 700px;
    min-height: 450px;
    position: relative;
  }
  .close {
    right: 20px;
    top: 20px;
    position: absolute;
    cursor: pointer;
    &:hover {
      -webkit-text-stroke-width: 0.05rem;
    }
  }
  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    .tabs__list {
      grid-gap: 10px 30px;
      justify-content: center;
    }
  }
  &__main {
    background-color: var(--white);
    border-radius: 15px;
    padding: 40px 25px;
    height: 100%;
    overflow: auto;
    @include scrollbar();
  }
  &__body {
    .table__row {
      grid-template-columns: 30% 35% 35%;
    }
    &--empty {
      font-weight: 600;
      font-size: 22px;
    }
    &--university {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      grid-gap: 25px;
      .table {
        width: 100%;
        &__row {
          grid-template-columns: 38% 34% 28%;
        }
      }
      .tabs__list {
        grid-gap: 10px 20px;
        justify-content: center;
      }
    }
  }
  @include adaptive(tablet-big) {
    &.popup-wrapper .modal-box {
      max-height: 700px;
    }
  }
}
</style>
