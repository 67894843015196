import axios from "axios";
import store from "@/store";

const backend = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_URL}/api`,
});

backend.interceptors.request.use(
  (config) => {
    config.headers.common = {
      ...config.headers.common,
      Authorization: `Bearer ${store.state.user.backendToken}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

backend.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) store.commit("user/killUser");

    throw error;
  }
);

export default backend;
