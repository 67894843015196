<template>
  <div class="popup-video__main">
    <iframe
      src="https://vk.com/video_ext.php?oid=-182279308&id=456239346&hd=4&autoplay=1"
      width="1920"
      height="1080"
      allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
      frameborder="0"
      allowfullscreen
      v-if="currentPopup.props.isTitle"
    ></iframe>
    <iframe
      :src="currentPopup.props.video"
      allowfullscreen
      allow="autoplay"
      v-else
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "VideoPopup",
  computed: {
    ...mapState(["currentPopup"]),
  },
  mounted() {
    window.addEventListener("keydown", this.escCloseModal);
  },
  destroy() {
    window.removeEventListener("keydown", this.escCloseModal);
  },
  methods: {
    escCloseModal(e) {
      if (this.currentPopup.isShown && e.key === "Escape") {
        this.currentPopup.isShown = false;
      }
    },
  },
};
</script>

<style lang="scss">
.popup-video {
  .modal-box {
    max-width: 960px;
    width: 100%;
    padding: 0;
  }
  &__main {
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    max-width: 960px;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      max-height: 540px;
    }
    video {
      width: 100%;
    }
  }
}
</style>
