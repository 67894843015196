<template>
  <div class="team-popup__content">
    <div class="team-popup__info">
      <div class="team-popup__info--top">
        <img :src="currentPopup.props.info.logo" alt="" />
        <p class="team-popup__info-title">
          {{ currentPopup.props.info.title }}
        </p>
        <a
          class="team-popup__info-link"
          :href="currentPopup.props.info.url_tg"
          target="_blank"
        >
          <Icon :type="'vk'" />
        </a>
      </div>
      <div class="team-popup__text">
        {{ currentPopup.props.info.description }}
      </div>
    </div>
    <Carousel
      :wrap-around="true"
      class="team-popup__gallery"
      v-if="currentPopup.props.gallery.length !== 0"
    >
      <Slide v-for="(item, index) in currentPopup.props.gallery" :key="index">
        <img :src="item.url" alt="" />
      </Slide>
      <template #addons>
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Icon from "@/components/Blocks/Icon";
import { Carousel, Pagination, Slide } from "vue3-carousel";
export default {
  name: "TeamPopup",
  components: { Icon, Carousel, Pagination, Slide },
  computed: {
    ...mapState(["currentPopup"]),
  },
  mounted() {
    window.addEventListener("keydown", this.escCloseModal);
  },
  destroy() {
    window.removeEventListener("keydown", this.escCloseModal);
  },
  methods: {
    escCloseModal(e) {
      if (this.currentPopup.isShown && e.key === "Escape") {
        this.currentPopup.isShown = false;
      }
    },
  },
};
</script>

<style lang="scss">
.team-popup {
  .modal-box {
    max-width: 1100px;
    width: 100%;
    background-color: var(--white);
  }
  &__content {
  }
  &__info {
    padding: 50px 40px;
    max-height: 320px;
    overflow-y: auto;
    @include scrollbar();
    &--top {
      display: flex;
      align-items: center;
      img {
        max-width: 120px;
        width: 100%;
        object-fit: cover;
      }
    }
    &-title {
      margin-left: 40px;
      font-size: 25px;
      line-height: 30px;
      font-weight: 600;
      color: var(--black);
    }
    &-link {
      margin-left: auto;
      font-size: 24px;
      color: var(--btn-gray-color1);
      cursor: pointer;
      &:hover {
        -webkit-text-stroke-width: 0.5px;
      }
    }
  }
  &__text {
    margin-top: 30px;
    font-size: 25px;
    line-height: 30px;
    font-weight: 400;
    color: var(--black);
  }
  &__gallery {
    max-height: 500px;
    position: relative;
    img {
      object-fit: cover;
      width: 100%;
      object-position: top;
      pointer-events: none;
      max-height: 500px;
    }
    .carousel {
      &__viewport {
        overflow: hidden;
      }
      &__track {
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
      }
      &__slide {
        min-width: 100%;
        overflow: hidden;
      }
      &__pagination {
        position: absolute;
        bottom: 20px;
        display: flex;
        gap: 20px;
        margin: 0 auto;
        justify-content: center;
        width: 100%;
        &-button {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: rgba(255, 255, 255, 0.8);
          transform: scale(1);
          transition: all 0.4s ease-in-out;
          &--active {
            background-color: #ffffff;
            transform: scale(1.5);
          }
        }
      }
    }
  }
  @include adaptive(tablet-big) {
    &.popup-wrapper {
      .modal-box {
        max-height: unset;
      }
    }
  }
  @include adaptive(phone) {
    &__info {
      padding: 20px;
      &--top {
        img {
          max-width: 70px;
        }
      }
      &-title {
        margin-left: 20px;
        font-size: 18px;
        line-height: 24px;
      }
    }
    &__text {
      margin-top: 15px;
      font-size: 14px;
      line-height: 17px;
    }
    &__gallery {
      img {
        max-height: 200px;
      }
    }
  }
}
</style>
