<template>
  <footer class="footer">
    <div class="container">
      <div class="footer__content">
        <div class="footer__content--top">
          <span>Контакты</span>
          <div class="footer__content-socials" v-if="!isMobile">
            <a href="https://vk.com/liga_forum"><Icon :type="'vk'" /></a>
            <a href="https://t.me/liga_forum_support">
              <Icon :type="'telegram'" />
            </a>
            <a href="https://t.me/liga_forum">
              <Icon :type="'telegram'" />
            </a>
          </div>
        </div>
        <div
          class="footer__content--mid"
          title="Контакты"
          itemscope
          itemtype="http://schema.org/Organization"
        >
          <div class="footer__content-contact" title="Почтовый адрес">
            <span>Сотрудничество</span>
            <a
              href="mailto:liga.forumrt@yandex.ru"
              target="_blank"
              itemprop="email"
              aria-label="'Написать письмо на оффициальную почту LIGA-FORUM'"
            >
              liga.forumrt@yandex.ru
            </a>
          </div>
          <div class="footer__content-contact" title="Почтовый адрес">
            <span>Пресс-служба</span>
            <a
              href="mailto:pressa.ligastudentov@gmail.com"
              target="_blank"
              itemprop="email"
              aria-label="'Написать письмо на оффициальную почту пресс службы LIGA-FORUM '"
            >
              pressa.ligastudentov@gmail.com
            </a>
          </div>
          <div class="footer__content-contact" title="Почтовый адрес">
            <span>Техническая поддержка</span>
            <a
              href="https://t.me/liga_forum_support"
              target="_blank"
              itemprop="email"
              aria-label="'Написать письмо в техническую поддержку LIGA-FORUM'"
            >
              @liga_forum_support
            </a>
          </div>
          <div class="footer__content-socials" v-if="isMobile">
            <a href="https://vk.com/liga_forum"><Icon :type="'vk'" /></a>
            <a href="https://t.me/liga_forum_support">
              <Icon :type="'telegram'" />
            </a>
            <a href="https://t.me/liga_forum">
              <Icon :type="'telegram'" />
            </a>
          </div>
        </div>
        <div class="footer__content--bottom">
          <a
            href="https://ligastudentov.com/"
            target="_blank"
            class="footer__content-link"
          >
            <span>2019-{{ moment().year() }}. Все права защищены. </span>
            <span>РМОО «Лига студентов Республики Татарстан» </span>
          </a>
          <router-link :to="{ name: 'Policy' }">
            Политика конфиденциальности
          </router-link>
        </div>
        <div itemscope itemtype="http://schema.org/Organization" v-show="false">
          <span itemprop="name">«ЛИГА ФОРУМ»</span>
          Контакты:
          <div
            itemprop="address"
            itemscope
            itemtype="http://schema.org/PostalAddress"
          >
            Адрес:
            <span itemprop="streetAddress">Габдуллы Тукая, 58</span>
            <span itemprop="postalCode"> 420021</span>
            <span itemprop="addressLocality">Казань</span>,
          </div>
          Телефон:<span itemprop="telephone">+7 843 231-81-41</span>, Факс:<span
            itemprop="faxNumber"
            >+7 843 231-81-41
          </span>
          , Электронная почта:
          <span itemprop="email">liga.forumrt@yandex.ru</span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Icon from "@/components/Blocks/Icon";
import moment from "moment";
export default {
  name: "Footer",
  components: { Icon },
  data() {
    return {
      width: window.innerWidth,
    };
  },
  computed: {
    isMobile() {
      return this.width < 759;
    },
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
    },
    moment,
  },
  created() {
    window.addEventListener("resize", this.updateWidth);
  },
};
</script>

<style lang="scss">
.footer {
  padding: 60px 0 30px;
  background-color: var(--black2);
  color: var(--white);
  &__content {
    &--top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 75px;
      span {
        @include text30();
      }
    }
    &-socials {
      display: flex;
      align-items: center;
      gap: 40px;
      i {
        font-size: 17px;
        cursor: pointer;
        color: var(--white);
        &:hover {
          -webkit-text-stroke-width: 0.5px;
        }
      }
    }
    &--mid {
      display: flex;
      justify-content: space-between;
      margin-bottom: 125px;
      flex-wrap: wrap;
      gap: 25px;
    }
    &-contact {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span {
        color: var(--footer-text-color);
        @include text20();
        margin-bottom: 5px;
        font-weight: 400;
      }
      a {
        @include text30();
        color: var(--white);
        font-weight: 600;
        &:hover {
          -webkit-text-stroke-width: 0.5px;
        }
      }
    }
    &--bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 18px;
      line-height: 25px;
      span {
        color: var(--footer-text-color);
      }
      a {
        color: var(--btn-purple-color);
        margin-top: 5px;
        &:hover {
          -webkit-text-stroke-width: 0.5px;
        }
      }
    }
    &-link {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  @include adaptive(tablet-small) {
    &__content {
      &--top {
        margin-bottom: 30px;
      }
      &-socials {
        margin: 0 auto;
        i {
          font-size: 20px;
        }
      }
      &-contact {
        align-items: center;
      }
      &--mid {
        flex-direction: column;
        align-self: center;
        margin-bottom: 30px;
      }
      &--bottom {
        margin: 0 auto;
        max-width: 210px;
        text-align: center;
        font-size: 11px;
        line-height: 13px;
      }
    }
  }
}
</style>
