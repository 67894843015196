export default {
  id: null,
  fio: "",
  email: "",
  fa2: "",
  phone: null,
  gender: "",
  telegram_name: null,
  telegram_link: "",
  first_name: null,
  last_name: null,
  middle_name: null,
  birthday: null,
  birthday_format: null,
  is_terms_confirm: false,
  locale: "ru",
  group: null,
  faculty: null,
  course_level: null,
  avatar_crop: null,
  created_at: "",
  info_city: {
    name: "",
    city_id: null,
    region_id: null,
  },
  info_region: {
    name: "",
    id: null,
  },
  info_university: {
    name: "",
    id: null,
    type: "",
  },
  socials: {
    data: {
      telegram: {
        href: "",
      },
      vk: {
        href: "",
      },
    },
  },
};
