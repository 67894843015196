<template>
  <div class="preloader" :class="overlayMode && 'preloader_overlay'">
    <div class="preloader__content">
      <div class="circle circle_first"></div>
      <div class="circle circle_second"></div>
      <div class="circle circle_third"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Preloader",
  props: {
    overlayMode: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.preloader {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  &__content {
    position: relative;
    width: 75px;
    height: 75px;
  }

  &_overlay {
    position: absolute;
    left: 0;
    top: 0;
  }
  &-block {
    background: transparent;
  }
}

.circle {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;

  &_first {
    background: var(--btn-blue-color1);
    animation: 3s cubic-bezier(1, 0.01, 1, 1) 0s infinite circle_first;
  }

  &_second {
    background: var(--btn-blue-color1);
    opacity: 0.1;
    animation: 3s cubic-bezier(1, 0.01, 1, 1) 0s infinite circle_second;
  }

  &_third {
    background: var(--btn-blue-color1);
    opacity: 0.4;
    animation: 3s cubic-bezier(1, 0.01, 1, 1) 0s infinite circle_third;
  }
}

@keyframes circle_first {
  0%,
  100% {
    transform: translate(53px, 36px);
  }
  17% {
    transform: translate(30px, 30px);
  }
  34% {
    transform: translate(0, 53px);
  }
  51% {
    transform: translate(30px, 30px);
  }
  68% {
    transform: translate(10px, 0);
  }
  85% {
    transform: translate(30px, 30px);
  }
  100% {
    transform: translate(53px, 36px);
  }
}

@keyframes circle_second {
  0%,
  100% {
    transform: translate(0, 53px);
  }
  17% {
    transform: translate(30px, 30px);
  }
  34% {
    transform: translate(10px, 0);
  }
  51% {
    transform: translate(30px, 30px);
  }
  68% {
    transform: translate(53px, 36px);
  }
  85% {
    transform: translate(30px, 30px);
  }
  100% {
    transform: translate(0, 53px);
  }
}

@keyframes circle_third {
  0%,
  100% {
    transform: translate(10px, 0);
  }
  17% {
    transform: translate(30px, 30px);
  }
  34% {
    transform: translate(53px, 36px);
  }
  51% {
    transform: translate(30px, 30px);
  }
  68% {
    transform: translate(0, 53px);
  }
  85% {
    transform: translate(30px, 30px);
  }
  100% {
    transform: translate(10px, 0);
  }
}
</style>
