export function setCurrentPopup(state, data) {
  Object.assign(state.currentPopup, data);
}
export function setFrontVersion(state, data) {
  state.frontVersion = data.front_version;
}
export function setStagesData(state, data) {
  state.storage.storageStagesData = data;
}
export function setShiftsData(state, data) {
  state.storage.storageShiftsData = data;
}
export function setShiftsOneData(state, [data, key]) {
  state.storage[key] = data;
}
export function setForumData(state, data) {
  state.storage.storageForumData = data;
}
export function setMembersData(state, data) {
  state.storage.storageMembersData = data;
}
export function setServicesData(state, data) {
  state.storage.storageServicesData = data;
}
export function setOneVideoData(state, data) {
  state.storage.storageOneVideoData = data;
}
export function setVideoData(state, data) {
  state.storage.storageVideoData = data;
}
export function setThreeData(state, data) {
  state.storage.storageThreeData = data;
}
export function setReviewsData(state, data) {
  state.storage.storageReviewsData = data;
}
export function setMaterialsData(state, data) {
  state.storage.storageMaterialsData = data;
}
export function setStickersData(state, data) {
  state.storage.storageStickersData = data;
}
export function setMerchData(state, data) {
  state.storage.storageMerchData = data;
}
export function setDirectionLimitData(state, data) {
  state.storage.storageDirectionLimitData = data;
}
export function setDirectionData(state, data) {
  state.storage.storageDirectionData = data;
}
export function setDirectionMemberServiceData(state, data) {
  state.storage.storageDirectionMemberServiceData = data;
}
export function setDirectionStaffServiceData(state, data) {
  state.storage.storageDirectionStaffServiceData = data;
}
export function setDirectionAppServiceData(state, data) {
  state.storage.storageDirectionAppServiceData = data;
}
export function setDirectionPRServiceData(state, data) {
  state.storage.storageDirectionPRServiceData = data;
}
export function setDirectionMotivationServiceData(state, data) {
  state.storage.storageDirectionMotivationServiceData = data;
}
export function setRoadmapData(state, data) {
  state.storage.storageRoadmapData = data;
}
export function setPartnersData(state, data) {
  state.storage.storagePartnersData = data;
}
export function setFAQData(state, data) {
  state.storage.storageFAQData = data;
}
export function setTeamsData(state, data) {
  state.storage.storageTeamsData = data;
}
export function setSomeData(state, { data, key }) {
  state.storage[key] = data;
}
export function setStatementParamsData(state, data) {
  state.storage.storageStatementParamsData = data;
}
export function setListTasksData(state, data) {
  state.storage.storageListTasksData = data;
}
export function setDataSaved(state, data) {
  state.dataSaved = data;
}
