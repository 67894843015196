<template>
  <div class="track-popup__main">
    <Icon :type="'crest'" @click="currentPopup.isShown = false" />
    <div class="track-popup__title">{{ currentPopup.props.item.title }}</div>
    <div class="track-popup__text">
      {{ currentPopup.props.item.description }}
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Icon from "@/components/Blocks/Icon";

export default {
  name: "TrackPopup",
  components: { Icon },
  computed: {
    ...mapState(["currentPopup"]),
  },
};
</script>

<style lang="scss">
.track-popup {
  &__main {
    background-color: var(--white);
    border-radius: 15px;
    padding: 25px;
    color: var(--black2);
    max-width: 380px;
    position: relative;
    i {
      position: absolute;
      right: 15px;
      top: 15px;
      font-size: 10px;
      cursor: pointer;
      &:hover {
        -webkit-text-stroke-width: 0.5px;
      }
    }
  }
  &__title {
    font-size: 18px;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 15px;
  }
  &__text {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
  }
  @include adaptive(phone) {
    &.popup-wrapper {
      justify-content: flex-start;
      margin: unset;
      .modal-box {
        margin: unset;
        padding-top: 30px;
      }
    }
  }
}
</style>
