<template>
  <div
    class="popup-wrapper"
    :class="currentPopup.props.popupClass"
    v-if="currentPopup.isShown"
  >
    <div
      class="modal-box"
      v-click-away="
        () => {
          if (
            currentPopup.name === 'AuthPopup' ||
            currentPopup.name === 'CropperPopup'
          ) {
          } else {
            currentPopup.isShown = false;
          }
        }
      "
    >
      <component v-if="currentPopup.name" v-bind:is="currentPopup.name" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Icon from "@/components/Blocks/Icon";
import { mixin as VueClickAway } from "vue3-click-away";
import VideoPopup from "@/components/Popups/VideoPopup";
import AuthPopup from "@/components/Popups/AuthPopup";
import TeamPopup from "@/components/Popups/TeamPopup";
import TrackPopup from "@/components/Popups/TrackPopup";
import ImagePopup from "@/components/Popups/ImagePopup";
import SavedPopup from "@/components/Popups/SavedPopup";
import CropperPopup from "@/components/Popups/CropperPopup";
import MapPopup from "@/components/Popups/MapPopup";
import RollbackPopup from "@/components/Popups/RollbackPopup";

export default {
  name: "PopupController",
  components: {
    RollbackPopup,
    MapPopup,
    CropperPopup,
    SavedPopup,
    ImagePopup,
    TeamPopup,
    AuthPopup,
    VideoPopup,
    TrackPopup,
    Icon,
  },
  data() {
    return {
      pageYOffset: 0,
    };
  },
  mixins: [VueClickAway],
  computed: {
    ...mapState(["currentPopup"]),
  },
  watch: {
    "$store.state.currentPopup.isShown": function () {
      this.$store.state.currentPopup.isShown
        ? document.body.classList.add("no-scroll")
        : document.body.classList.remove("no-scroll");
    },
  },
  methods: {
    closePopup() {
      this.$store.commit("setCurrentPopup", { isShown: false });
    },
  },
};
</script>

<style scoped></style>
