<template>
  <div
    class="header-dropdown"
    v-if="this.$store.state.storage.storageShiftsData.length"
  >
    <button
      class="header-dropdown__btn"
      @click="isShown = !isShown"
      :class="{ 'header-dropdown__btn--active': isShown }"
    >
      Участникам
      <Icon :type="'open-arrow'" />
    </button>
    <SmoothHeight>
      <div
        class="header-dropdown__list"
        v-show="isShown"
        v-click-away="
          () => {
            isShown = false;
            if (this.isMobile) {
              isShown = true;
            }
          }
        "
      >
        <router-link
          v-for="(item, index) in shiftArray"
          :key="index"
          :to="{ name: 'Shift', params: { slug: item.slug } }"
          @click="
            () => {
              this.isShown = false;
              this.$store.state.dropdownOpen = false;
            }
          "
        >
          <span>{{ item.name }}</span>
        </router-link>
      </div>
    </SmoothHeight>
  </div>
</template>

<script>
import Icon from "@/components/Blocks/Icon";
import { mixin as VueClickAway } from "vue3-click-away";
import SmoothHeight from "@/components/Blocks/SmoothHeight";
export default {
  name: "HeaderDropdown",
  components: { SmoothHeight, Icon },
  mixins: [VueClickAway],
  data() {
    return {
      isShown: false,
      width: window.innerWidth,
      shifts: [
        { name: "I смена", id: 1 },
        { name: "II смена", id: 2 },
        { name: "III смена", id: 3 },
      ],
    };
  },
  computed: {
    info() {
      return this.$store.state.storage.storageShiftsData;
    },
    isMobile() {
      return this.width < 768;
    },
    shiftArray() {
      return this.info.map((item, index) => {
        let slug = "first";
        if (index === 1) {
          slug = "second";
        }
        if (index === 2) {
          slug = "third";
        }
        return {
          id: item.id,
          slug: slug,
          name: `${this.shifts[index].name} «${item.title}»`,
        };
      });
    },
  },
  methods: {
    updateWidth() {
      this.width = window.innerWidth;
    },
  },
  created() {
    window.addEventListener("resize", this.updateWidth);
  },
};
</script>

<style lang="scss">
.header-dropdown {
  position: relative;
  &__list {
    display: grid;
    gap: 8px;
    grid-template-columns: 1fr;
    position: absolute;
    left: -75px;
    padding: 16px 20px;
    background-color: var(--white);
    top: calc(100% + 8px);
    border-radius: 2px;
    box-shadow: 0 4px 10px var(--black2);
    transition: 0.4s;
    a {
      font-size: 16px;
      line-height: 19px;
      color: var(--btn-gray-color1);
      font-weight: 400;
      transition: all 0.4s ease-in-out;
      &:hover {
        color: var(--btn-purple-color);
        -webkit-text-stroke-width: 0.2px;
      }
    }
  }
  &__btn {
    font-size: 18px;
    line-height: 25px;
    color: var(--white);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 60px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    i {
      font-size: 13px;
      margin-left: 5px;
      transform: rotate(0);
      transition: transform 0.3s ease;
    }
    &--active {
      color: var(--btn-purple-color);
      i {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
