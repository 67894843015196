<template>
  <div class="popup-save__content">
    <Icon :type="'crest'" @click="currentPopup.isShown = false" />
    <span>
      Ваши данные не сохранены. Вы действительно хотите покинуть страницу?
    </span>
    <div class="popup-save__btns">
      <Button red @click="goNext"> Да,перейти </Button>
      <Button @click="currentPopup.isShown = false">Нет,остаться</Button>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Blocks/Icon";
import Button from "@/components/Blocks/Button";
import { mapState } from "vuex";
export default {
  name: "SavedPopup",
  components: { Button, Icon },
  computed: {
    ...mapState(["currentPopup"]),
  },
  methods: {
    goNext() {
      this.$store.state.dataSaved = true;
      this.currentPopup.isShown = false;
      this.$router.push({ name: `${this.$store.state.nextRoute}` });
    },
  },
};
</script>

<style lang="scss">
.popup-save {
  .modal-box {
    max-width: 500px;
    width: 100%;
    padding: 0;
    overflow: unset;
    min-height: unset !important;
  }
  &__content {
    background-color: #ffffff;
    border-radius: 15px;
    height: 100%;
    padding: 60px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow-y: auto;
    @include scrollbar();
    i {
      position: absolute;
      right: 15px;
      top: 15px;
      font-size: 20px;
      cursor: pointer;
      &:hover {
        -webkit-text-stroke-width: 0.5px;
      }
    }
  }
  &__btns {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }
}
</style>
