<template>
  <button class="btn" :class="className" @click="handleClick">
    <span class="btn__slot">
      <slot></slot>
    </span>
    <div class="btn__preloader">
      <div class="btn__anim"></div>
    </div>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    red: Boolean,
    blue: Boolean,
    white: Boolean,
    purple: Boolean,
    disabled: Boolean,
    loading: Boolean,
    small: Boolean,
  },
  computed: {
    className() {
      return {
        "btn--red": this.red,
        "btn--blue": this.blue,
        "btn--white": this.white,
        "btn--purple": this.purple,
        "btn--disabled": this.disabled,
        "btn--small": this.small,
        "btn--loading": this.loading,
      };
    },
  },
  methods: {
    handleClick() {
      if (!this.disabled) this.$emit("protected-click");
    },
  },
};
</script>

<style lang="scss">
.btn {
  padding: 10px 45px;
  border: 2px solid var(--black2);
  border-radius: 12px;
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  display: block;
  text-transform: uppercase;
  a {
    color: inherit;
  }
  &:hover {
    border-color: var(--black2);
    color: var(--white);
    background-color: var(--black2);
  }
  &--disabled {
    &.btn,
    &.btn--purple {
      border-color: var(--btn-disabled-color);
      color: var(--btn-disabled-color);
    }
    &.btn--red,
    &.btn--blue,
    &.btn--white {
      border-color: var(--btn-disabled-color);
      color: var(--btn-gray-color1);
      background-color: var(--btn-disabled-color);
      box-shadow: unset;
      pointer-events: none;
    }
  }
  &--loading {
    position: relative;
    overflow: hidden;
    border-color: transparent;
    background-color: var(--btn-disabled-bg);
    color: var(--calendar-disabled);
    pointer-events: none;
    &.btn,
    &.btn--purple {
      border-color: var(--btn-disabled-color);
      color: var(--btn-disabled-color);
    }
    &.btn--red,
    &.btn--blue,
    &.btn--white {
      border-color: var(--btn-disabled-color);
      color: var(--btn-gray-color1);
      background-color: var(--btn-disabled-color);
      box-shadow: unset;
    }
    .btn__preloader {
      display: grid;
    }
  }
  &__preloader {
    display: none;
    background-color: rgba(0, 0, 0, 0.1);
    @include full-absolute();
  }
  &__slot {
    white-space: nowrap;
  }
  &__anim {
    width: 1em;
    height: 1em;
    border-radius: 50%;
    margin: auto;
    position: relative;
    transform: translateZ(0);
    color: var(--white-black-color);
    animation: btn-preloader 1.5s ease infinite;
  }
  &--purple {
    color: var(--white);
    padding: 5px 20px;
    border-color: var(--btn-purple-color);
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    &:hover {
      background-color: var(--btn-purple-color);
      color: var(--white);
      border-color: var(--btn-purple-color);
    }
  }
  &--white {
    color: var(--btn-purple-color);
    background-color: var(--white);
    border-color: var(--white);
    &:hover {
      background-color: var(--btn-gray-color2);
      color: var(--btn-purple-color);
      border-color: var(--btn-gray-color2);
    }
  }
  &--red {
    background-color: var(--btn-red-color1);
    border-color: var(--btn-red-color1);
    box-shadow: 0 1.5px 7.5px var(--btn-red-color1);
    color: var(--white);
    font-size: 18px;
    &:hover {
      background-color: var(--btn-red-color2);
      border-color: var(--btn-red-color2);
      color: var(--white);
    }
  }
  &--blue {
    background-color: var(--btn-blue-color1);
    border-color: var(--btn-blue-color1);
    box-shadow: 0 1.5px 7.5px var(--btn-blue-color1);
    color: var(--white);
    &:hover {
      background-color: var(--btn-blue-color2);
      border-color: var(--btn-blue-color2);
      color: var(--white);
    }
  }
  &--small {
    padding: 5px 7px;
    font-size: 12px;
  }
  @include adaptive(phone) {
    font-size: 12px;
    line-height: 14px;
    white-space: nowrap;
    padding: 10px 30px;
    &--small {
      padding: 5px 7px;
      font-size: 12px;
    }
  }
}
.winter {
  .btn {
    &--purple {
      &:hover {
        color: var(--black);
      }
    }
    &--red {
      box-shadow: unset;
    }
    &--blue {
      box-shadow: unset;
    }
  }
}
</style>
