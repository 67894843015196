<template>
  <div class="input" :class="className">
    <input
      :type="type"
      :name="type"
      :id="uniqueId"
      v-model="value"
      :class="value ? 'is-focused' : ''"
      :disabled="disabled"
      :readonly="readonly"
      v-maska="mask"
      :autocomplete="type"
      :placeholder="placeholder"
      v-bind="$attrs"
      :min="min"
      :max="max"
    />
    <label :for="uniqueId">{{ label }}</label>
    <div class="form__error" v-if="errorArray && errorArray[0]">
      {{ errorArray[0].$message }}
    </div>
    <div class="form__error" v-else-if="errorText">
      {{ errorText }}
    </div>
  </div>
</template>

<script>
import uniqueString from "unique-string";
import { maska } from "maska";

export default {
  name: "Input",
  directives: { maska },
  data() {
    return {
      uniqueId: uniqueString(),
    };
  },
  props: {
    label: String,
    modelValue: [String, Number],
    placeholder: [String, Number],
    type: String,
    error: Boolean,
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    errorArray: Array,
    mask: String,
    errorText: String,
    min: String,
    max: String,
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    className() {
      return {
        "input--error": this.error,
        "input--disabled": this.disabled,
      };
    },
  },
};
</script>

<style lang="scss"></style>
