export default () => ({
  currentPopup: {
    name: "",
    props: {},
    isShown: false,
    goNext: false,
  },
  currentShiftId: null,
  cropShow: false,
  avatar: undefined,
  currentRegion: "",
  dropdownOpen: false,
  frontVersion: null,
  frontVersionChanged: false,
  isDataLoading: false,
  dataSaved: true,
  nextRoute: "",
  storage: {
    storageForumData: [],
    storageShiftsData: [],
    storageShiftsOneDatafirst: {},
    storageShiftsOneDatasecond: {},
    storageShiftsOneDatathird: {},
    storageStagesData: [],
    storageMembersData: {},
    storageServicesData: [],
    storageOneVideoData: {},
    storageThreeData: [],
    storageVideoData: [],
    storageReviewsData: [],
    storageMaterialsData: [],
    storageStickersData: [],
    storageMerchData: [],
    storageDirectionLimitData: [],
    storageDirectionData: [],
    storageDirectionMemberServiceData: [],
    storageDirectionStaffServiceData: [],
    storageDirectionAppServiceData: [],
    storageDirectionPRServiceData: [],
    storageDirectionMotivationServiceData: [],
    storageRoadmapData: [],
    storagePartnersData: [],
    storageFAQData: [],
    storageTeamsData: [],
    storageStatementParamsData: {},
    storageListTasksData: {},
  },
  dontSavedCrop: undefined,
  designMode: "summer",
});
