<template>
  <Layout>
    <router-view> </router-view>
  </Layout>
  <transition name="popup-anim">
    <PopupController />
  </transition>
</template>

<script>
import PopupController from "@/components/Popups/PopupController";
import Layout from "@/components/Layout";
export default {
  name: "App",
  components: { Layout, PopupController },
  mounted() {
    const app = document.getElementById("app");
    if (this.$store.state.designMode === "winter") app.classList.add("winter");
  },
};
</script>

<style lang="scss">
@import "~@/scss/style.scss";
</style>
