<template>
  <div class="tabs" :class="className">
    <div class="tabs__list">
      <div class="tabs-item" v-for="(item, index) in tabsItems" :key="index">
        <input
          :id="`tabs${index}_${uniqComponentId}`"
          type="radio"
          v-model="value"
          :value="Number(item.value)"
        />
        <label :for="`tabs${index}_${uniqComponentId}`">{{ item.key }}</label>
      </div>
      <div class="form__error" v-if="errorArray && errorArray[0]">
        {{ errorArray[0].$message }}
      </div>
    </div>
  </div>
</template>

<script>
import uniqueString from "unique-string";

export default {
  name: "Tabs",
  data() {
    return {
      uniqComponentId: uniqueString(),
    };
  },
  props: {
    modelValue: [Boolean, Number],
    error: Boolean,
    disabled: Boolean,
    errorArray: Array,
    items: {
      type: Array,
      default: () => [],
    },
    keys: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    className() {
      return {
        "tabs--error": this.error,
        "tabs--disabled": this.disabled,
      };
    },
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    tabsItems() {
      return this.items.map((item, index) => {
        return { key: this.keys[index] ?? item, value: item };
      });
    },
  },
};
</script>

<style lang="scss">
.tabs {
  &--error {
    position: relative;
    margin-bottom: 15px;
    .form__error {
      color: var(--red);
      position: absolute;
      top: 100%;
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
    }
  }
  &__list {
    display: flex;
    align-items: center;
    gap: 40px;
    flex-wrap: wrap;
  }
  &-item {
    label {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 18px;
      line-height: 25px;
      color: var(--black2);
      padding: 10px 20px;
      border: 2px solid var(--btn-blue-color1);
      transition: all 0.3s ease-in-out;
      border-radius: 15px;
      &:hover {
        background-color: var(--btn-blue-color1);
        color: var(--white);
      }
    }
    input {
      display: none;
      &:checked + label {
        background-color: var(--btn-blue-color1);
        color: var(--white);
      }
      margin: 0;
      padding: 0;
    }
  }
  &--disabled {
    pointer-events: none;
    .tabs-item {
      label {
        border-color: var(--btn-gray-color1);
      }
      input {
        &:checked + label {
          background-color: var(--btn-gray-color1);
          border-color: var(--btn-gray-color1);
        }
      }
    }
  }
  @include adaptive(tablet-small) {
    &__list {
      gap: 10px;
    }
  }
  @include adaptive(phone) {
    &-item {
      label {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
}
</style>
