export function protectedAvatarSrc(path) {
  let src = null;

  if (typeof path == "string") src = path;
  else if (typeof path == "object") src = path.url;

  return src ? src : require("@/assets/img/reviews-img.png");
}
export function copyToClipboard(text) {
  let area = document.createElement("textarea");
  document.body.appendChild(area);
  area.value = text;
  area.select();
  document.execCommand("copy");
  document.body.removeChild(area);
}
export function externalValidationAssigner(formFields, apiFields, errorObj) {
  let validations = errorObj["only_user_fields"];
  if (Object.keys(validations).length === 0) return {};

  let result = {};
  Object.keys(validations).forEach((field) => {
    const fieldIndex = apiFields.findIndex((e) => e === field);
    if (validations[field] && fieldIndex !== -1)
      result[formFields[fieldIndex]] = validations[field][0];
  });

  return result;
}
export function userIdentity(client) {
  if (!client) return "";
  if (typeof client == "string") return client;

  const { first_name, last_name, nickname, email } = client;

  if (first_name && last_name) return first_name + " " + last_name;
  else if (first_name) return first_name;
  else if (last_name) return last_name;
  else if (nickname) return nickname;
  else if (email) return email;
}
export function formatMoney(amount) {
  if (!amount) return 0;
  let val = (amount / 1).toFixed(2).replace(".00", "");
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0");
}
export function percentFromNumber(small, big) {
  return 100 / (big / small);
}
export function checkTrueEmails(email) {
  let invalidEmails = [
    "@mil.ru",
    "@naik.ru",
    "@meil.ru",
    "@maul.ru",
    "@tandex.ru",
    "@lmail.ru",
    "@email.ru",
    "@mail.eu",
    "@mll.ru",
    "@miil.ru",
    "@mai.ru",
    "@mal.ru",
    "@mail.com",
    "@mali.ru",
    "@mial.ru",
    "@yandez.ru",
    "@yanddx.ru",
    "@yanded.ru",
    "@yansex.ru",
    "@yandeex.ru",
    "@yamdex.ru",
    "@gkail.ru",
    "@gmil.com",
    "@gmal.com",
    "@gnail.com",
    "@gimail.com",
    "@gmmail.com",
    "@gamil.ru",
    "@ignail.com",
    "@gmail.ru",
    "@gmal.ru",
    "@gmai.com",
    "@gmai.ru",
    "@gmali.com",
    "@gmail.cm",
    "@dmail.com",
    "@bmail.com",
    "@vmail.com",
    "@fmail.com",
    "@tmail.com",
    "@gkail.com",
    "@gamil.com",
    "@hmail.ru",
    "@hotmail.ru",
    "@outlook.com",
    "@hotmail.com",
    "@outlook.ru",
    "@bo.ru",
    "@bj.ru",
    "@bl.ru",
    "@ilcoud.com",
    "@icloud.ru",
    "@icloudd.com",
    "@liat.ru",
    "@lisr.ru",
    "@invox.ru",
    "@stud.kphu.ru",
    "@kpfu.stud.ru",
    "@amil.ru",
    "@email.com",
    "@yandex.eu",
    "@gmaqil.com",
    "gmaik.com",
    "@interet.ru",
    "@iclod.com",
    "|",
  ];

  let ru = /[а-яё]+/i.test(email);
  let checkedEmail = invalidEmails.map((item) => {
    return email.indexOf(item) > -1;
  });
  return (
    checkedEmail.some((item) => {
      return item === true;
    }) || ru
  );
}
