import userPropertiesList from "@/enums/userPropertiesList";
import { protectedAvatarSrc } from "@/utils/helpers";
export const userProps = (state) => {
  let props = Object.create(userPropertiesList);
  Object.assign(props, state.user);
  return props;
};
export const userAvatarSrc = (state) => {
  return protectedAvatarSrc(state.user.avatar);
};
