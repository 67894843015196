import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/404.vue"),
  },
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: {
      header:
        "«ЛИГА ФОРУМ» – студенческий образовательный форум с образовательной программой.",
      headerDescription:
        "«ЛИГА ФОРУМ» – это студенческий образовательный форум, открытая площадка для общения и обмена идеями, где каждый студент пройдет образовательную программу по интересному ему направлению.",

      keywords:
        "ЛИГАФОРУМ, студенческий форум, образовательный форум, студенты, форум, обучение, мероприятие, студенческий фестиваль, Лига форум, Лига студентов",
      url: "https://liga-forum.ru",
    },
  },
  {
    path: "/shift/:slug",
    name: "Shift",
    component: () => import("@/views/Shift.vue"),
    meta: {
      keywords:
        "ЛИГАФОРУМ, PROFESSIONALS, UNIVERSITIES, COMMUNITY, сменыЛИГАФОРУМ, Лига форум, Лига студентов, студенты вузов, студенты колледжей",
    },
  },
  {
    path: "/direction",
    name: "Direction",
    component: () => import("@/views/Direction.vue"),
    meta: {
      header: "Организаторы студенческого образовательного форума ЛИГАФОРУМ",
      headerDescription:
        "Команда организаторов, благодаря которым и существует ЛИГАФОРУМ. Каждый член команды вносит неотъемлемый вклад в организацию ЛИГАФОРУМ.",
      keywords:
        "ЛИГАФОРУМ, команда, дирекция, организаторы, исполнительный директор форума, программный директор, PR-директор, директор штаба, РуфатКиямов, АлияКиямова, Рахмат Халилов, КамоллидинБобохонов, Дмитрий Томкевич, ГульшатГабдрахманова, Лига форум, Лига студентов",
      url: "https://liga-forum.ru/direction",
    },
  },
  {
    path: "/prizes-shop",
    name: "PrizesShop",
    component: () => import("@/views/PrizesShop.vue"),
    meta: {
      header: "ЛИГАФОРУМ–магазин эксклюзивных товаров",
      headerDescription:
        "Выполняй задания мотивационной программы, войди в ТОП-30 участников форума и стань обладателем нашей лимитированной продукции.",
      keywords:
        "ЛИГАФОРУМ, призы, товары, мерч, подарки, футболка, толстовка, шоппер, бутылка, флешкарта, поясная сумка, Лига форум, Лига студентов",
      url: "https://liga-forum.ru/prizes-shop",
      soon: true,
    },
  },
  {
    path: "/video-gallery",
    name: "VideoGallery",
    component: () => import("@/views/VideoGallery.vue"),
    meta: {
      header: "Галерея ЛИГАФОРУМ",
      headerDescription:
        "ЛИГАФОРУМ – прекрасное место, чтобы получить много незабываемых впечатлений.Никто другой не сможет рассказать об этом событии лучше, чем ты!Пиши о своих эмоциях, делись впечатлениями, приглашай друзей и знакомых регистрироваться на сайте ЛИГАФОРУМ.",
      keywords:
        "ЛИГАФОРУМ, галерея, воспоминания ЛИГАФОРУМ, Лига форум, Лига студентов",
      url: "https://liga-forum.ru/video-gallery",
    },
  },
  {
    path: "/policy",
    name: "Policy",
    component: () => import("@/views/Policy.vue"),
    meta: {
      header: "ЛИГАФОРУМ– политика в отношении обработки персональных данных.",
      headerDescription:
        "Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных РМОО «Лига студентов Республики Татарстан» (далее – Оператор).",
      keywords:
        "ЛИГАФОРУМ, политика конфиденциальности, Лига форум, Лига студентов",
      url: "https://liga-forum.ru/policy",
    },
  },
  {
    path: "/lk",
    name: "LK",
    component: () => import("@/views/LK.vue"),
    meta: {
      header:
        "«ЛИГА ФОРУМ» – студенческий образовательный форум с образовательной программой.",
      headerDescription:
        "«ЛИГА ФОРУМ» – это студенческий образовательный форум, открытая площадка для общения и обмена идеями, где каждый студент пройдет образовательную программу по интересному ему направлению.",
      keywords:
        "ЛИГАФОРУМ, студенческий форум, образовательный форум, студенты, форум, обучение, мероприятие, студенческий фестиваль, Лига форум, Лига студентов",

      url: "https://liga-forum.ru/policy",
    },
    children: [
      {
        path: "",
        name: "LkAbout",
        meta: {
          authFlow: true,
          beforePopup: true,
        },
        component: () => import("@/components/LkSections/LkAbout.vue"),
      },
      {
        path: "request",
        name: "LkRequest",
        meta: {
          authFlow: true,
        },
        component: () => import("@/components/LkSections/LkRequest.vue"),
      },
      {
        path: "my-requests",
        name: "LkMyRequests",
        meta: {
          authFlow: true,
        },
        component: () => import("@/components/LkSections/LkMyRequests.vue"),
      },
      {
        path: "tasks",
        name: "LkTasks",
        meta: {
          authFlow: true,
          soon: true,
        },
        component: () => import("@/components/LkSections/LkTasks.vue"),
      },
    ],
  },
];

const router = createRouter({
  mode: "history",
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  },
});

//Guards and redirections
router.beforeEach((to, from, next) => {
  if (to.meta.authFlow && !store.state.user.isAuthenticated)
    next({ name: "Home" });
  else if (to.matched[0].path === "/lk") next({ name: "Home" });
  else if (to.meta.soon) next({ name: "Home" });
  else next();
});

router.afterEach((to) => {
  if (to.meta.header) document.title = to.meta.header;
  if (to.meta.header)
    document
      .querySelector('head meta[property="og:title"]')
      .setAttribute("content", to.meta.header);
  if (to.meta.headerDescription)
    document
      .querySelector('head meta[name="description"]')
      .setAttribute("content", to.meta.headerDescription);
  if (to.meta.headerDescription)
    document
      .querySelector('head meta[property="og:description"]')
      .setAttribute("content", to.meta.headerDescription);
  if (to.meta.url)
    document
      .querySelector('head meta[property="og:url"]')
      .setAttribute("content", to.meta.url);
  if (to.meta.keywords)
    document
      .querySelector('head meta[name="keywords"]')
      .setAttribute("content", to.meta.keywords);
});
export default router;
