import backend from "@/api/rest";

export async function getFrontInfo({ commit }) {
  await backend.get("/config/info").then((result) => {
    commit("setFrontVersion", result.data);
  });
}
export async function openSavedPopup({ commit }) {
  await commit("setCurrentPopup", {
    name: "SavedPopup",
    isShown: true,
    props: {
      popupClass: "popup-save",
    },
  });
}
