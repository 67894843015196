<template>
  <header
    class="header"
    :class="[className, { 'header--mobile': $store.state.dropdownOpen }]"
  >
    <div class="container">
      <div class="header__content">
        <router-link :to="{ name: 'Home' }">
          <img
            :src="
              $store.state.designMode === 'winter'
                ? require('@/assets/img/logo-winter.webp')
                : require('@/assets/img/logo.svg')
            "
            alt="Logo"
          />
        </router-link>
        <div class="header__btns">
          <HeaderDropdown />
          <!--          <Button purple @click="toLk">Подать заявку</Button>-->
          <a
            href="https://lk.ligastudentov.com/"
            class="btn btn--purple"
            target="_blank"
          >
            ПОДАТЬ ЗАЯВКУ
          </a>
        </div>
      </div>
      <div class="header__content--mobile">
        <div class="header-mobile__btns">
          <a href="https://lk.ligastudentov.com/" target="_blank">
            <Icon :type="'account'" />
          </a>
          <router-link
            :to="{ name: 'Home' }"
            class="header__logo"
            @click="$store.state.dropdownOpen = false"
          >
            <img
              :src="
                $store.state.designMode === 'winter'
                  ? require('@/assets/img/logo-winter.webp')
                  : require('@/assets/img/logo.svg')
              "
              alt="Лого"
              width="120"
              height="30"
            />
          </router-link>
          <div
            class="header__burger"
            @click="$store.state.dropdownOpen = !$store.state.dropdownOpen"
            :class="{ 'header__burger--active': $store.state.dropdownOpen }"
          >
            <div></div>
          </div>
        </div>
        <div class="header-mobile__content" v-show="$store.state.dropdownOpen">
          <div class="header-mobile__list">
            <HeaderDropdown />
            <router-link
              :to="{ name: 'Direction' }"
              @click="$store.state.dropdownOpen = false"
            >
              <span>Дирекция</span>
            </router-link>
            <!--            <router-link :to="{ name: 'LkTasks' }">-->
            <!--              <span @click="toTasks">Задания</span>-->
            <!--            </router-link>-->
          </div>
          <div class="header-mobile__lk">
            <!--            <Button blue @click="toLk">Подать заявку</Button>-->
            <a
              href="https://lk.ligastudentov.com/"
              class="btn btn--blue"
              target="_blank"
            >
              ПОДАТЬ ЗАЯВКУ
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Button from "@/components/Blocks/Button";
import HeaderDropdown from "@/components/Blocks/HeaderDropdown";
import Icon from "@/components/Blocks/Icon";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";

export default {
  name: "Header",
  components: { Icon, HeaderDropdown, Button },
  data() {
    return {
      dropdownOpen: false,
    };
  },
  watch: {
    "$store.state.dropdownOpen": function () {
      this.$store.state.dropdownOpen
        ? document.body.classList.add("no-scroll-header")
        : document.body.classList.remove("no-scroll-header");
    },
  },
  props: {
    absolute: Boolean,
    lk: Boolean,
  },
  computed: {
    className() {
      return {
        "header--absolute": this.absolute,
        "header--lk": this.lk,
      };
    },
  },
  methods: {
    toLk() {
      if (this.$store.state.user.isAuthenticated === false) {
        this.$store.commit("setCurrentPopup", {
          name: "AuthPopup",
          isShown: true,
          props: {
            popupClass: "auth-popup",
          },
        });
      } else {
        this.$router.push({ name: "LkAbout" });
      }
      this.$store.state.dropdownOpen = false;
    },
    toTasks() {
      if (this.$store.state.user.isAuthenticated === false) {
        this.$store.commit("setCurrentPopup", {
          name: "AuthPopup",
          isShown: true,
          props: {
            popupClass: "auth-popup",
          },
        });
      } else {
        this.$router.push({ name: "LkTasks" });
      }
      this.$store.state.dropdownOpen = false;
    },
  },
  beforeMount() {
    if (!this.$store.state.storage.storageShiftsData.length) {
      this.$store.state.isLoadingData = true;
      createRequest(requestConfigs.GETShiftList)
        .then((result) => {
          this.$store.commit("setShiftsData", result.data);
        })
        .finally(() => {
          this.$store.state.isLoadingData = false;
        });
    }
  },
};
</script>

<style lang="scss">
.header {
  background: url("~@/assets/img/header-bg.webp") no-repeat center;
  padding: 35px 0;
  background-size: cover;
  position: relative;
  &:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
  }
  &-mobile {
    &__btns {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
    &__content {
      margin-top: 40px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }
    &__lk {
      margin-top: 20px;
    }
  }
  &__content {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      max-width: 128px;
    }
    &--mobile {
      display: none;
      position: relative;
      z-index: 1;
      i {
        color: var(--white);
        font-size: 24px;
      }
    }
  }
  &__logo {
    position: absolute;
    left: calc(50% - 60px);
    i {
      font-size: 27px;
    }
    img {
      width: 120px;
      height: 35px;
      object-fit: contain;
    }
  }
  &__burger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    div {
      display: block;
      width: 24px;
      height: 1.5px;
      background-color: var(--white);
      border-radius: 1.5px;
      margin: 6px 0;
    }
    &::before,
    &::after {
      content: "";
      display: block;
      width: 24px;
      height: 2px;
      background-color: var(--white);
      border-radius: 1.5px;
      transform: rotate(0);
      transition: all 0.4s ease;
    }
    &--active {
      align-items: center;
      justify-content: center;
      height: 24px;
      &::before {
        width: 28px;
        transform: rotate(45deg);
        position: relative;
        top: 2px;
      }
      &::after {
        width: 28px;
        transform: rotate(-45deg);
        position: relative;
      }
      div {
        display: none;
      }
    }
  }
  &__btns {
    display: flex;
    align-items: center;
  }
  &--absolute {
    position: absolute;
    background: unset;
    width: 100%;
    &:after {
      display: none;
    }
  }
  &--lk {
    background: unset;
    background-color: var(--btn-blue-color1);
    &:after {
      display: none;
    }
  }
  @include adaptive(tablet-small) {
    padding: 20px 0;
    &--mobile {
      background-color: var(--white) !important;
      z-index: 10;
      min-height: 100vh;
      background-image: unset;
      &::after {
        display: none;
      }
      & > i {
        display: none;
      }
      .container {
        min-height: 100vh;
      }
      .header__logo {
        i {
          color: var(--btn-blue-color1);
        }
      }
      .header__burger {
        margin-left: auto;
        div {
          background-color: var(--btn-blue-color1);
        }
        &::before,
        &::after {
          background-color: var(--btn-blue-color1);
        }
      }
      .header__content--mobile {
        min-height: 100vh;
      }
      .header-dropdown__btn {
        color: var(--black);
        font-size: 14px;
        line-height: 17px;
        text-transform: unset;
        font-weight: 400;
        i {
          color: var(--black);
          font-size: 9px;
          position: relative;
          top: 1px;
        }
      }
      .header-dropdown__list {
        position: unset;
        box-shadow: unset;
        border-radius: 15px;
        background-color: #f0f0f0;
        margin-top: 15px;
        max-width: 235px;
        a {
          flex-shrink: 0;
        }
        span {
          color: var(--black);
          font-size: 14px;
          line-height: 17px;
        }
      }
      .header-mobile__list {
        display: flex;
        flex-direction: column;
        gap: 10px;
        a {
          font-size: 14px;
          line-height: 17px;
          color: var(--black);
        }
      }
      .header-mobile__btns {
        i {
          color: var(--btn-blue-color1);
        }
      }
    }
    &__content {
      display: none;
      &--mobile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}
.winter {
  .header {
    &::after {
      background: url(/img/snowflakes.dbd01ced.svg) rgba(0, 0, 0, 0.6);
    }
    &--lk {
      background: url(/img/snowflakes.dbd01ced.svg) var(--btn-blue-color1);
    }
  }
}
</style>
