<template>
  <div class="cropper-popup__content">
    <Preloader v-if="!imgSrc" />
    <div class="cropper-popup__viewport" v-else>
      <vue-cropper
        class="cropper-popup__cropper"
        ref="cropper"
        :guides="true"
        :view-mode="2"
        drag-mode="crop"
        :auto-crop-area="0.5"
        :background="true"
        :restore="false"
        :rotatable="false"
        :zoomable="false"
        :scale="1"
        :responsive="true"
        :checkOrientation="false"
        :src="imgSrc"
        alt="Source Image"
        :aspectRatio="1"
        :minCropBoxWidth="100"
        :minCropBoxHeight="100"
      />
    </div>
    <div class="cropper-popup__btns">
      <Button @click="cancelCrop"> Отменить </Button>
      <Button @click="cropImage" blue> Загрузить фото </Button>
    </div>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import Button from "@/components/Blocks/Button";
import Preloader from "@/components/Blocks/Preloader";
export default {
  name: "CropperPopup",
  components: {
    Preloader,
    Button,
    VueCropper,
  },
  data() {
    return {
      imgSrc: "",
      cropImgPOST: "",
    };
  },
  methods: {
    setImage(e) {
      const reader = new FileReader();
      reader.onload = (event) => {
        this.imgSrc = event.target.result;
      };
      reader.readAsDataURL(e);
    },
    async cropImage() {
      let base64Image = await fetch(
        this.$refs.cropper
          .getCroppedCanvas({ maxWidth: 250, maxHeight: 250 })
          .toDataURL()
      );
      this.cropImgPOST = await base64Image.blob();
      this.$store.state.dontSavedCrop = this.$refs.cropper
        .getCroppedCanvas()
        .toDataURL();
      this.$store.commit("setCurrentPopup", {
        isShown: false,
        props: {
          cropImgPOST: this.cropImgPOST,
        },
      });
    },
    cancelCrop() {
      this.$store.state.currentPopup.isShown = false;
    },
  },
  beforeMount() {
    this.setImage(this.$store.state.currentPopup.props.file);
  },
};
</script>

<style lang="scss">
.cropper-popup {
  &.popup-wrapper .modal-box {
    max-width: 500px;
    height: unset;
    min-height: 300px;
    max-height: 700px;
    overflow: hidden;
    background-color: var(--main-bg-color);
  }
  .cropper-container {
  }
  .cropper-canvas {
    width: 100% !important;
    img {
      width: 100% !important;
      object-fit: contain;
    }
  }
  @include adaptive(tablet-big) {
    &.popup-wrapper .modal-box {
      max-width: unset;
      width: 100%;
    }
  }
  @include adaptive(tablet-small) {
    .cropper-container {
      width: 100% !important;
      position: relative;
    }
  }
  &__content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    @include scrollbar();
    position: relative;
  }
  &__viewport {
    flex-grow: 1;
  }
  &__cropper {
    width: 100%;
    height: 100%;
  }
  &__btns {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    position: sticky;
    bottom: 5px;
    .btn:first-child {
      background-color: var(--white);
      &:hover {
        background: var(--btn-gray-color2);
        border-color: var(--btn-gray-color2);
        color: var(--black);
      }
    }
    button {
      flex-grow: 1;
      flex-basis: 30%;
    }
  }
}
</style>
