<template>
  <div class="layout" :class="this.$store.state.showSidebar ? 'no-scroll' : ''">
    <div class="main">
      <div class="main__wrapper">
        <Header
          :absolute="$route.name === 'Home' || $route.name === 'Shift'"
          :lk="$route.matched[0]?.path === '/lk'"
        />
        <div class="slot__wrapper">
          <slot />
        </div>
      </div>
      <Footer />
      <a
        class="layout__tg"
        href="https://t.me/liga_forum_support"
        target="_blank"
        data-tooltip="Техподдержка"
      >
        <Icon type="telegram" />
      </a>
    </div>
  </div>
</template>

<script>
import Header from "@/components/LayoutSections/Header";
import Footer from "@/components/LayoutSections/Footer";
import Icon from "@/components/Blocks/Icon";
export default {
  name: "Layout",
  components: { Icon, Footer, Header },
  data() {
    return {
      timeout: null,
    };
  },
  watch: {
    "$store.state.frontVersion"() {
      for (let key in this.$store.state.storage) {
        if (typeof this.$store.state.storage[key] === "object") {
          this.$store.state.storage[key] = {};
        } else if (this.$store.state.storage[key].isArray()) {
          this.$store.state.storage[key].length = 0;
        }
      }
      this.$store.commit("user/killUserFull");
      window.location.reload();
    },
    $route() {
      Promise.all([this.$store.dispatch("getFrontInfo")]);
    },
  },
};
</script>

<style lang="scss">
.layout {
  display: flex;
  min-height: 100vh;
  position: relative;
  &__tg {
    background: #255fa9;
    padding: 20px;
    position: fixed;
    bottom: 50px;
    left: calc(100vw - 150px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    i {
      color: white;
      font-size: 30px;
    }
    &:hover {
      background: #19b919;
    }
    &[data-tooltip]:hover::after {
      left: -4em;
      right: 4em;
      width: 130px;
      font-size: 16px;
      top: -40px;
    }
    @include adaptive(tablet-small) {
      padding: 15px;
      left: calc(100vw - 90px);
      a {
        height: 25px;
        width: 25px;
      }
      i {
        font-size: 25px;
      }
    }
  }
}
.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  &__wrapper {
    flex-grow: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 50px;
  }
}
.slot {
  &__wrapper {
    flex-grow: 1;
  }
}
</style>
