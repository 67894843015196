export function setUser(state, data) {
  state.user = data;
}
export function setBackendToken(state, data) {
  state.backendToken = data;
}
export function setIsAuthenticated(state, data) {
  state.isAuthenticated = data;
}
export function killUser(state) {
  state.isAuthenticated = false;
  state.backendToken = "";
  window.location.reload(true);
}
export function killUserFull(state) {
  state.user = {};
}
export const setUserSocials = (state, data) => {
  state.userSocials = data;
};
