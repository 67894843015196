<template>
  <div class="image-popup__main">
    <div class="image-popup__img">
      <img :src="currentPopup.props.image" alt="" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ImagePopup",
  computed: {
    ...mapState(["currentPopup"]),
  },
  mounted() {
    window.addEventListener("keydown", this.escCloseModal);
  },
  destroy() {
    window.removeEventListener("keydown", this.escCloseModal);
  },
  methods: {
    escCloseModal(e) {
      if (this.currentPopup.isShown && e.key === "Escape") {
        this.currentPopup.isShown = false;
      }
    },
  },
};
</script>

<style lang="scss">
.image-popup {
  .modal-box {
    max-width: 1200px;
  }
  @include adaptive(tablet-big) {
    .modal-box {
      max-height: unset;
    }
  }
}
</style>
