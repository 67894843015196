<template>
  <div class="textarea" :class="className">
    <textarea
      name=""
      id=""
      v-model="comment"
      :placeholder="placeholder"
      v-bind="$attrs"
    ></textarea>
    <div class="form__error" v-if="errorArray && errorArray[0]">
      {{ errorArray[0].$message }}
    </div>
    <div class="form__error" v-else-if="errorText">
      {{ errorText }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TextAreaBlock",
  props: {
    modelValue: [String, Number],
    placeholder: { type: [String, Number], default: "Комментарий..." },
    errorArray: Array,
    error: Boolean,
    disabled: Boolean,
    errorText: String,
  },
  computed: {
    comment: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    className() {
      return {
        "textarea--error": this.error,
        "textarea--disabled": this.disabled,
      };
    },
  },
};
</script>

<style lang="scss">
.textarea {
  textarea {
    padding: 20px;
    background: var(--input-color);
    border: 2px solid var(--input-color);
    min-width: 100%;
    font-size: 18px;
    line-height: 25px;
    color: var(--gray);
    border-radius: 15px;
    resize: both;
    overflow: auto;
    @include scrollbar();
  }
  @include adaptive(phone) {
    textarea {
      padding: 10px;
      font-size: 12px;
      line-height: 14px;
    }
  }
  &--error {
    position: relative;
    margin-bottom: 15px;
    .form__error {
      color: var(--red);
      position: absolute;
      top: 100%;
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
    }
    textarea {
      border: 1px solid var(--red);
    }
  }
  &--disabled {
    pointer-events: none;
    textarea {
      border-color: #f0f0f0;
      background-color: #f0f0f0;
    }
  }
}
</style>
