<template>
  <div class="rollback-popup__main">
    <Icon :type="'crest'" @click="currentPopup.isShown = false" class="close" />
    <div class="rollback-popup__head">
      Вы действительно хотите отозвать заявку?
    </div>
    <div class="rollback-popup__body">
      <TextAreaBlock
        v-model="comment_refusal"
        :error="!!errorText"
        :error-text="errorText"
        @blur="validateText($event.target.value)"
      />
    </div>
    <div class="rollback-popup__btns">
      <Button
        red
        :disabled="comment_refusal.length < 3 || comment_refusal.length > 100"
        @click="rollbackStatement"
        :loading="isBtnLoading"
      >
        Отозвать
      </Button>
      <Button blue @click="currentPopup.isShown = false">Нет</Button>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Blocks/Icon";
import { mapState } from "vuex";
import TextAreaBlock from "@/components/Blocks/TextAreaBlock";
import Button from "@/components/Blocks/Button";
import { createRequest } from "@/api/requestBuilder";
import requestConfigs from "@/api/requestConfigs";
export default {
  name: "RollbackPopup",
  components: { Button, TextAreaBlock, Icon },
  data() {
    return {
      comment_refusal: "",
      errorText: "",
      isBtnLoading: false,
    };
  },
  computed: {
    ...mapState(["currentPopup", "user"]),
    ...mapState("user", ["user"]),
  },
  methods: {
    validateText(e) {
      if (e.length < 3) {
        this.errorText = "Количество символов не менее 2";
      } else if (e.length > 100) {
        this.errorText = "Количество символов не более 100";
      } else this.errorText = "";
    },
    rollbackStatement() {
      if (this.errorText) return;
      this.isBtnLoading = true;
      createRequest(requestConfigs.POSTRollbackStatement, {
        routerPayload: {
          id: this.currentPopup.props.id,
        },
        jsonPayload: {
          comment_refusal: this.comment_refusal,
        },
      })
        .then((response) => {
          let statement = this.user.info_statements?.data?.find((item) => {
            return item.id === this.currentPopup.props.id;
          });
          Object.assign(statement, response.data);
        })
        .finally(() => {
          this.isBtnLoading = false;
          this.currentPopup.isShown = false;
        });
    },
  },
};
</script>

<style lang="scss">
.rollback-popup {
  &.popup-wrapper .modal-box {
    width: 100%;
    max-height: 700px;
    min-height: 300px;
    position: relative;
    max-width: 500px;
  }
  .close {
    right: 20px;
    top: 20px;
    position: absolute;
    cursor: pointer;
    &:hover {
      -webkit-text-stroke-width: 0.05rem;
    }
  }
  &__main {
    background-color: var(--white);
    border-radius: 15px;
    padding: 40px 25px;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    grid-gap: 25px;
    textarea {
      min-height: 150px;
    }
    @include scrollbar();
  }
  &__btns {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 20px;
    .btn {
      flex-grow: 1;
      max-width: 200px;
      &--purple {
        color: var(--btn-purple-color);
        &:hover {
          color: white;
        }
      }
    }
  }
}
</style>
